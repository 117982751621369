import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { useQuery } from 'react-query'
import { useQuery as useCoreQuery } from 'packages/core'

export const PEOPLES_KEY = 'PEOPLES'

export const usePeoples = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        sortOrder,
        sortBy,
        ownedBy,
        uncPath,
        location,
        shouldLoadTags,
        advancedSearchTags,
    } = state
    const friendlyName = state['advancedSearch.forms.friendlyName'] || ''
    const login = state['advancedSearch.forms.login'] || ''
    const email = state['advancedSearch.forms.email'] || ''
    const enabled = state['advancedSearch.forms.enabled']

    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (shouldLoadTags) {
        queryData.includeTags = true
    }
    if (location) {
        queryData.locationId = location.id
    }
    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (login) {
        advancedSearch.push({
            name: 'Login',
            type: 'string',
            value: login,
        })
    }
    if (email) {
        advancedSearch.push({
            name: 'Email',
            type: 'string',
            value: email,
        })
    }
    if (enabled) {
        advancedSearch.push({
            name: 'Active',
            type: 'boolean',
            value: enabled.value,
        })
    }
    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            PEOPLES_KEY,
            'LIST_MY_PEOPLE',
            searchTerm,
            sortBy,
            sortOrder,
            enabled,
            friendlyName,
            login,
            email,
            ownedBy,
            uncPath,
            location,
            advancedSearchTags,
            shouldLoadTags,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `api/people/allPeople`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const usePeopleDetails = (id: any) => {
    const callApi = useAxios()
    const query = useCoreQuery()
    const returnedFromWorkflow = query.get('returnedFromWorkflow') === 'true'

    return useQuery(
        [PEOPLES_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people?personId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
            retry: (failureCount, error: any) => {
                return returnedFromWorkflow && error.response.status === 404
                    ? false
                    : true
            },
        },
    )
}

export const usePeopleAssignedApplication = (peopleId: any) => {
    const callApi = useAxios()
    const data = {
        skip: 0,
        take: 500,
        eligibilityAssigneeId: peopleId,
        sortBy: 'friendlyName',
    }
    return useQuery(
        [PEOPLES_KEY, 'APPLICATION_LIST', peopleId],
        () =>
            callApi({
                method: 'POST',
                url: `/api/applications/byeligibility`,
                data: data,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: Boolean(peopleId),
        },
    )
}

export const usePeopleDynamicFormData = (
    peopleId: any,
    resourceTypeId: any,
) => {
    const callApi = useAxios()
    return useQuery(
        [PEOPLES_KEY, 'PEOPLE_DYNAMIC_FIELDS', peopleId, resourceTypeId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people/GetEditPersonJsonTemplate/${peopleId}/${resourceTypeId}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled: Boolean(peopleId) && Boolean(resourceTypeId),
        },
    )
}

export const usePeopleFormDropDownOption = (url: any) => {
    const callApi = useAxios()
    return useQuery(
        [PEOPLES_KEY, 'PEOPLE_FORM_DD_OPTIONS', url],
        () =>
            callApi({
                method: 'GET',
                url: url,
            }).then((data) => data.data),
        {
            enabled: Boolean(url),
        },
    )
}

export const useDynamicFormTreeTree = (url: any, searchTerm: any) => {
    const callApi = useAxios()
    return useQuery(
        [PEOPLES_KEY, 'DYNAMIC_FORM_TREE', searchTerm, url],
        () =>
            callApi({
                method: 'GET',
                url: `${url}${searchTerm ? '?searchTerm=' + searchTerm : ''}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(url),
        },
    )
}
export const usePeopleDynamicCardForm = (enabled: Boolean) => {
    const callApi = useAxios()
    return useQuery(
        [PEOPLES_KEY, 'PEOPLE_DYNAMIC_FIELDS_FOR_BASIC_CARD'],
        () =>
            callApi({
                method: 'GET',
                url: `api/people/DetailsJsonTemplate`,
            }).then((data) => data.data),
        {
            enabled,
        },
    )
}

export const usePeopleLocations = () => {
    const callApi = useAxios()
    return useQuery(
        [PEOPLES_KEY, 'LOCATIONS'],
        () =>
            callApi({
                method: 'GET',
                url: '/api/Lookups/people/Locations',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
