import { isNilOrEmpty } from 'packages/core'
import config from 'config'
import i18n from 'i18next'

export const headerHeight = 70
export const subHeaderHeight = 70
export const smallScreenWidth = '1022px'
export const tabletScreenWidth = '768px'
export const mobileScreenWidth = '767px'
export const imagesDisabled =
    config.DISABLE_IMAGES?.toString().toLowerCase() === 'true'

const DIRECT_ASSIGNED_LOCATIONS_MAX_LIMIT = 5

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
}

export const RightTypes = {
    ApplicationRight: 5,
    AzureRole: 8,
    Scope: 9,
}

export const ApplicationTypes = {
    NotPbacAndNotAzure: 'NotPBACAndNotAzure',
    PbacHasNoAppResources: 'PBACHasNoAppResource',
    PbacHasAppResourcesWithoutFieldType: 'PBACHasAppResourceWithoutFieldType',
    PbacHasAppResourcesWithFieldType: 'PBACHasAppResourceWithFieldType',
    PBACHasNoAppResourceWithFieldType: 'PBACHasNoAppResourceWithFieldType',
    AzureApp: 'AzureApp',
    AzureAppWithPbac: 'AzureApplicationsWithPBAC',
    AzureApplicationsWithAppResourcesAndPBAC:
        'AzureApplicationsWithAppResourcesAndPBAC',
}

export const convertListToFriendlyNameCsv = (
    locations: { friendlyName: string }[],
    maxLimit: number = DIRECT_ASSIGNED_LOCATIONS_MAX_LIMIT,
) => {
    let locationsString = ''
    if (!locations) return '-'
    let visibileLocations: { friendlyName: string }[] = locations.slice(
        0,
        maxLimit,
    )
    locationsString = visibileLocations.map((l) => l.friendlyName).join(', ')

    if (locations.length > maxLimit) {
        locationsString += `, +${locations.length - maxLimit} ${i18n.t('More')}`
    }

    return locationsString
}

export const isApplicationAzureApp = (applicationType: string) => {
    return applicationType
        ? [ApplicationTypes.AzureApp].includes(applicationType)
        : false
}

export const isApplicationPbacApp = (applicationType: string) => {
    return applicationType
        ? [
              ApplicationTypes.PbacHasAppResourcesWithFieldType,
              ApplicationTypes.PbacHasAppResourcesWithoutFieldType,
              ApplicationTypes.PbacHasNoAppResources,
              ApplicationTypes.PBACHasNoAppResourceWithFieldType,
          ].includes(applicationType)
        : false
}

export const isApplicationPbacAppHavingAppResources = (
    applicationType: string,
) => {
    return (
        applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType ||
        applicationType === ApplicationTypes.PbacHasAppResourcesWithFieldType
    )
}

export const isApplicationPbacAppHavingFieldTypes = (
    ApplicationType: string,
) => {
    return (
        ApplicationType === ApplicationTypes.PbacHasAppResourcesWithFieldType ||
        ApplicationType === ApplicationTypes.PBACHasNoAppResourceWithFieldType
    )
}

export const isApplicationAzureAppWithPbac = (applicationType: string) => {
    return applicationType
        ? [
              ApplicationTypes.AzureAppWithPbac,
              ApplicationTypes.AzureApplicationsWithAppResourcesAndPBAC,
          ].includes(applicationType)
        : false
}

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const paginate = (items: any, pageNumber: any, pageSize: any) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}
export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}

export const getMasterLockFieldsWidth = (
    headings: any,
    tableHeadingRefs: any,
) => {
    let allRefWidth = 0
    const masterLockFields = headings.filter(
        (x: { showMasterLock: any }) => x.showMasterLock,
    )
    if (masterLockFields?.length > 0) {
        masterLockFields.forEach((item: { name: any }) => {
            const itemIndex = headings.findIndex(
                (x: { name: any }) => x.name === item.name,
            )
            if (itemIndex >= 0) {
                allRefWidth = tableHeadingRefs.current[itemIndex]?.current
                    ?.offsetWidth
                    ? allRefWidth +
                      tableHeadingRefs.current[itemIndex].current.offsetWidth
                    : allRefWidth
            }
        })
    }
    return allRefWidth
}

export const getMasterLockLeftSpace = (
    headings: any,
    tableHeadingRefs: any,
) => {
    let allRefOnLeftWidth = 0
    let keysBeforeMasterPass: any[] = []
    let isMasterLockFound = false
    headings.forEach((element: { showMasterLock: any; name: any }) => {
        if (!element.showMasterLock && !isMasterLockFound) {
            keysBeforeMasterPass.push(element.name)
        } else {
            isMasterLockFound = true
        }
    })
    keysBeforeMasterPass.forEach((item) => {
        const itemIndex = headings.findIndex(
            (x: { name: any }) => x.name === item,
        )
        if (itemIndex >= 0) {
            allRefOnLeftWidth = tableHeadingRefs.current[itemIndex]?.current
                ?.offsetWidth
                ? allRefOnLeftWidth +
                  tableHeadingRefs.current[itemIndex].current.offsetWidth
                : allRefOnLeftWidth
        }
    })
    return allRefOnLeftWidth
}
export const getAppliedFiltersValues = (state: any) => {
    let values: any = []
    const defaultFiltersKeys = [
        'highLevelClassification',
        'groupType',
        'uncPath',
        'businessFunction',
        'location',
        'application',
        'rights',
        'accountStoreUsageType',
        'accountStore',
        'showOnlyAzureApplications',
        'ownedBy',
        'advancedSearch.forms.name',
        'advancedSearch.forms.description',
        'advancedSearch.forms.friendlyName',
    ]
    defaultFiltersKeys.map((key) => {
        if (state[key]) {
            values.push({ [key]: state[key] })
        }
    })
    return values
}

export const compareObjects = (
    obj1: any,
    obj2: any,
    propsToCompare: string[],
) => {
    if (!obj1 || !obj2 || !propsToCompare.length) {
        return []
    }
    const difference: string[] = []
    propsToCompare.forEach((p: string) => {
        if (obj1[p] !== obj2[p]) {
            difference.push(p)
        }
    })

    return difference
}

export const defaultDataProtectedSubcomponent = 'NotProtected'

export const getAssignmentTypeIcon = (type: string) => {
    switch (type) {
        case 'Group':
            return 'ApplicationRolesSmall'
        case 'Management Role':
            return 'ManagementRole'
        case 'Business Role and Location':
            return 'BusinessRoles'
        case 'Business Role':
            return 'BusinessRoles'
        case 'Management Role Definition':
            return 'ManagementRole'
        case 'Person':
            return 'ViewProfile'
        default:
            return 'ViewProfile'
    }
}

export const RESOURCE_TYPES = {
    GROUP: 'Group',
    SETGROUP: 'SetGroup',
    PERSON: 'Person',
    MANAGEMENTROLE: 'Management Role',
    MANAGEMENTROLEDEFINITION: 'Management Role Definition',
    QUERYBASEDCOLLECTION: 'Query-Based Collection',
    BUSINESSROLEANDLOCATION: 'Business Role and Location',
    LOCALRIGHT: 'LocalRight',
}

export const ASSIGNMENT_TYPES = {
    ADD: 'Add',
    REMOVE: 'Remove',
    GRANT: 'Grant',
    UN_GRANT: 'UnGrant',
    UPDATE_TIME_CONSTRAIN: 'UpdateTimeConstrain',
}
export const ELIGIBILITY_ASSIGNMENT_TYPES = {
    DIRECT: 1,
    RESULTANT: 2,
}
export const ELIGIBILITY_TYPES = [
    {
        label: 'Eligible',
        value: 'Eligible',
        id: 1,
    },
    {
        label: 'Suggested',
        value: 'Suggested',
        id: 2,
    },
    {
        label: 'PreApproved',
        value: 'PreApproved',
        id: 3,
    },
]
export const ITEMS_VIEW = {
    LIST: 'list',
    CARD: 'card',
}

export function updateUrlQueryParams(
    query: URLSearchParams,
    history: any,
    paramsToSet: any,
    paramsToDelete: any,
) {
    try {
        paramsToSet.forEach((p: any) => {
            query.set(p.key, p.value)
        })
        paramsToDelete.forEach((p: any) => {
            query.delete(p)
        })
        history.push(`${history.location.pathname}?${query.toString()}`)
    } catch (err) {}
}

export function checkTabAccess(
    tabs: any,
    selectedTab: any,
    hasAccessToControl: any,
) {
    try {
        if (!selectedTab) {
            return true
        }
        // Check if matched with any tab or not
        const selectedTabInfo = tabs.find(
            (x: any) =>
                x.value.toLowerCase() === selectedTab.toLowerCase() &&
                x.display,
        )
        if (!selectedTabInfo) {
            return false
        }

        // If Selected value from URL matched with one value and then checkf for access to that tab
        // Case: When tab is not protected
        if (!selectedTabInfo?.requireAccess?.control) {
            return true
        }

        // Case: Check if access is granted or not
        const isAccessible = hasAccessToControl(
            selectedTabInfo?.requireAccess?.control,
        )
        return isAccessible ? true : false
    } catch (err) {}
}

export function getTabsBasedOnAccess(tabs: any, hasAccessToControl: any) {
    try {
        const filteredTabs = tabs.filter((x: any) => {
            if (checkTabAccess(tabs, x.value, hasAccessToControl)) {
                return x
            }
        })

        return filteredTabs?.length ? filteredTabs : []
    } catch (err) {}
}

export function getAccessibleTabs(
    query: any,
    history: any,
    tabs: any,
    urlActiveTab: any,
    urlSubActiveTab: any,
    hasAccessToControl: any,
    urlParentKey: any,
    urlChildKey: any,
) {
    try {
        let queryActiveTab = ''
        let querySubActiveTab = ''
        const accessibleTabs = getTabsBasedOnAccess(tabs, hasAccessToControl)
        const defaultValue = accessibleTabs?.length
            ? accessibleTabs[0].value
            : ''
        const allAccessibleTabs = accessibleTabs?.length ? accessibleTabs : []
        if (
            urlActiveTab &&
            checkTabAccess(tabs, urlActiveTab, hasAccessToControl)
        ) {
            queryActiveTab = urlActiveTab
            // Check for second level
            if (urlSubActiveTab) {
                const tempSubTabs = tabs.find(
                    (x: any) =>
                        x.value.toLowerCase() ===
                        queryActiveTab.toLocaleLowerCase(),
                )
                const isSubTabExist = tempSubTabs?.subTabs?.length
                    ? true
                    : false
                if (
                    isSubTabExist &&
                    !checkTabAccess(
                        tempSubTabs?.subTabs,
                        urlSubActiveTab,
                        hasAccessToControl,
                    )
                ) {
                    // Check if subtab is typed wrongly in URL then redirect to default correct one
                    if (isSubTabExist) {
                        const accessibleSubTabs = getTabsBasedOnAccess(
                            tempSubTabs?.subTabs,
                            hasAccessToControl,
                        )
                        const defaultSubValue = accessibleSubTabs?.length
                            ? accessibleSubTabs[0].value
                            : ''
                        if (defaultSubValue) {
                            querySubActiveTab = defaultSubValue
                            updateUrlQueryParams(
                                query,
                                history,
                                [
                                    {
                                        key: urlParentKey,
                                        value: queryActiveTab,
                                    },
                                    {
                                        key: urlChildKey,
                                        value: defaultSubValue,
                                    },
                                ],
                                [],
                            )
                        } else {
                            queryActiveTab = defaultValue
                            updateUrlQueryParams(
                                query,
                                history,
                                [{ key: urlParentKey, value: defaultValue }],
                                [urlChildKey],
                            )
                        }
                    } else {
                        queryActiveTab = defaultValue
                        updateUrlQueryParams(
                            query,
                            history,
                            [{ key: urlParentKey, value: defaultValue }],
                            [urlChildKey],
                        )
                    }
                } else {
                    querySubActiveTab = urlSubActiveTab
                }
            }
            // Below case is for when we have subTabs in iverview screen
        } else if (!urlActiveTab && urlSubActiveTab) {
            const isTabAccessible = checkTabAccess(
                tabs,
                urlSubActiveTab,
                hasAccessToControl,
            )
            if (!isTabAccessible) {
                const accessibleSubTabs = getTabsBasedOnAccess(
                    tabs,
                    hasAccessToControl,
                )
                const defaultSubValue = accessibleSubTabs?.length
                    ? accessibleSubTabs[0].value
                    : ''
                if (defaultSubValue) {
                    querySubActiveTab = defaultSubValue
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlChildKey, value: defaultSubValue }],
                        [],
                    )
                } else {
                    queryActiveTab = defaultValue
                    querySubActiveTab = ''
                    updateUrlQueryParams(query, history, [], [urlChildKey])
                }
            } else {
                querySubActiveTab = urlSubActiveTab
                updateUrlQueryParams(
                    query,
                    history,
                    [{ key: urlChildKey, value: urlSubActiveTab }],
                    [],
                )
            }
        } else {
            queryActiveTab = defaultValue
            // Check if any subtab exist
            // This case suppose we are on first tab and having subTbas. Then URL will show subTabs.
            // On navigating to first default the sub tabs remain in URL if the next tab constains the no siub tabs or wrong if contains
            const tempSubTabs = tabs.find(
                (x: any) =>
                    x.value.toLowerCase() === defaultValue.toLocaleLowerCase(),
            )
            const isSubTabExist = tempSubTabs?.subTabs?.length ? true : false
            if (isSubTabExist) {
                const accessibleSubTabs = getTabsBasedOnAccess(
                    tempSubTabs.subTabs,
                    hasAccessToControl,
                )
                const defaultSubValue = accessibleSubTabs?.length
                    ? accessibleSubTabs[0].value
                    : ''
                if (defaultSubValue) {
                    querySubActiveTab = defaultSubValue
                    updateUrlQueryParams(
                        query,
                        history,
                        [
                            { key: urlParentKey, value: defaultValue },
                            { key: urlChildKey, value: defaultSubValue },
                        ],
                        [],
                    )
                } else {
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlParentKey, value: defaultValue }],
                        [urlChildKey],
                    )
                }
            } else {
                if (queryActiveTab) {
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlParentKey, value: defaultValue }],
                        [urlChildKey],
                    )
                }
            }
        }

        return {
            activeTab: queryActiveTab,
            querySubActiveTab: querySubActiveTab,
            allAccessibleTabs: allAccessibleTabs,
        }
    } catch (err) {
        return {
            activeTab: '',
            allAccessibleTabs: [],
        }
    }
}
export const RESOURCE_TYPE_NAMES = {
    GROUPS: 'Groups',
    MANAGEMENT_ROLES: 'ManagementRoles',
    SHARED_FOLDER: 'SharedFolder',
    APPLICATIONS: 'Applications',
    PEOPLE: 'People',
    MAILBOXES: 'Mailboxes',
    SHARED_FOLDERS: 'SharedFolders',
    LOCALRIGHT: 'LocalRight',
    LOCALROLE: 'LocalRole',
    GLOBAL_FIELD_TYPE: 'GlobalFieldType',
    PERSON: 'Person',
}

export const updateFieldTypeJson = (
    oldJson: any,
    keyToFind: any,
    valueToFind: any,
    updatedValue: any,
    parentKeyValue: any,
) => {
    try {
        if (oldJson instanceof Array) {
            for (let i = 0; i < oldJson.length; i++) {
                updateFieldTypeJson(
                    oldJson[i],
                    keyToFind,
                    valueToFind,
                    updatedValue,
                    parentKeyValue,
                )
            }
        } else if (typeof oldJson === 'object') {
            for (let prop in oldJson) {
                if (
                    prop === keyToFind &&
                    oldJson[prop] === valueToFind &&
                    oldJson?.parentRightId === parentKeyValue
                ) {
                    return Object.assign(oldJson, updatedValue)
                } else {
                    updateFieldTypeJson(
                        oldJson[prop],
                        keyToFind,
                        valueToFind,
                        updatedValue,
                        parentKeyValue,
                    )
                }
            }
        }
        return oldJson
    } catch (err) {}
    return oldJson
}

export const checkDeepEqualityOfObjects = (obj1: any, obj2: any) => {
    try {
        if (typeof obj1 !== typeof obj2) {
            return false
        }
        if (typeof obj1 !== 'object' || obj1 === null) {
            return obj1 === obj2
        }
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) {
                return false
            }
            for (let i = 0; i < obj1.length; i++) {
                if (!checkDeepEqualityOfObjects(obj1[i], obj2[i])) {
                    return false
                }
            }
            return true
        }
        if (typeof obj1 === 'object' && typeof obj2 === 'object') {
            const keys1 = Object.keys(obj1)
            const keys2 = Object.keys(obj2)
            if (keys1.length !== keys2.length) {
                return false
            }
            for (const key of keys1) {
                if (!checkDeepEqualityOfObjects(obj1[key], obj2[key])) {
                    return false
                }
            }
            return true
        }
        return false
    } catch (err) {
        return false
    }
}

const findChangesInAssignedValues = (
    oldArray: any,
    newArray: any,
    keyToMatch: any,
) => {
    const changes = oldArray.map((oldItem: any) => {
        const newItem = newArray.find(
            (item: any) => item[keyToMatch] === oldItem[keyToMatch],
        )
        if (!newItem) {
            return { ...oldItem, ActionType: 'Deleted' }
        } else if (JSON.stringify(oldItem) !== JSON.stringify(newItem)) {
            return { ...newItem, ActionType: 'Added' }
        } else {
            return { ...newItem, ActionType: 'NoChange' }
        }
    })

    newArray.forEach((newItem: any) => {
        const found = oldArray.find(
            (item: any) => item[keyToMatch] === newItem[keyToMatch],
        )
        if (!found) {
            changes.push({ ...newItem, ActionType: 'Added' })
        }
    })

    return changes
}

export const findChangedFieldTypesForAppRole = (
    apiData: any,
    updatedAppRoles: any,
    fieldTypeKeysToExclude: any,
) => {
    let changedData = {}
    try {
        const newData = { ...updatedAppRoles }
        const oldData = { ...apiData }
        // Assuming the structure is consistent, iterate over the Rights array
        const oldRights = oldData.Rights || []
        const newRights = newData.Rights || []
        const updatedRights = newRights.map((item: any) => {
            const oldRight = oldRights.find(
                (x: any) => x.AzLocalRightID === item.AzLocalRightID,
            )
            const oldFieldTypes = oldRight?.FieldType || []
            const rightFieldTypes = item?.FieldType || []
            const fieldTypeArray = rightFieldTypes.map((item: any) => {
                const fieldType = getModifiedFieldTypeValue(oldFieldTypes, item)
                const filteredObject = Object.assign({}, fieldType)

                fieldTypeKeysToExclude.forEach((key: any) => {
                    delete filteredObject[key]
                })
                return filteredObject
            })
            return {
                ...item,
                FieldType: fieldTypeArray,
            }
        })
        if (updatedRights?.length) {
            changedData = {
                ...updatedAppRoles,
                Rights: updatedRights,
            }
        } else {
            changedData = {
                ...updatedAppRoles,
            }
        }
    } catch (err: any) {
        console.log('Err', err.message || err)
    }

    return changedData
}

export const findChangedFieldTypesForAppRight = (
    apiData: any,
    updatedFieldTypes: any,
    fieldTypeKeysToExclude: any,
) => {
    let changedData = {}
    try {
        const newData = { ...updatedFieldTypes }
        const oldData = { ...apiData }
        const oldFieldTypes = oldData.FieldType || []
        const newFieldTypes = newData.FieldType || []
        const fieldTypeArray = newFieldTypes.map((item: any) => {
            const fieldType = getModifiedFieldTypeValue(oldFieldTypes, item)
            const filteredObject = Object.assign({}, fieldType)

            fieldTypeKeysToExclude.forEach((key: any) => {
                delete filteredObject[key]
            })
            return filteredObject
        })
        if (fieldTypeArray?.length) {
            changedData = {
                ...updatedFieldTypes,
                FieldType: fieldTypeArray,
            }
        } else {
            changedData = {
                ...updatedFieldTypes,
            }
        }
    } catch (err: any) {
        console.log('Err', err.message || err)
    }

    return changedData
}

export const getFieldTypeRequiredErrorMessage = (item: any) => {
    let errorMessage = ''
    try {
        if (item?.IsRequired) {
            const valueType =
                item?.AzFieldTypeSelectionRuleTypeID === 3
                    ? 'AssignedValues'
                    : 'AssignedValue'

            if (
                item?.SelectionRuleType === 'Range' &&
                (item?.SelectionRule === 'FreeTextRange' ||
                    item?.SelectionRule === 'FixedRange')
            ) {
                if (!item?.AssignedValueFrom || !item?.AssignedValueTo) {
                    errorMessage = 'Common_SelectRangeForFieldType'
                }
            } else {
                if (typeof item?.[valueType] === 'string') {
                    if (!item[valueType]) {
                        errorMessage = 'Common_SelectFieldTypeValue'
                    }
                } else if (Array.isArray(item?.[valueType])) {
                    if (!item[valueType]?.length) {
                        errorMessage = 'Common_SelectItemFromListForFieldType'
                    }
                } else {
                    if (!item[valueType]?.FriendlyName) {
                        errorMessage = 'Common_SelectFieldTypeValue'
                    }
                }
            }
        }
    } catch (err) {}
    return errorMessage
}

export const checkIfAnyFieldTypeMissingValue = (appRight: any) => {
    let isRequiredFilled = true
    try {
        appRight?.FieldType?.forEach((item: any) => {
            if (!checkAllRequiredFieldTypeFilled(item)) {
                isRequiredFilled = false
            }
        })
    } catch (err) {}

    return isRequiredFilled
}

export const checkAllRequiredFieldTypeFilled = (item: any) => {
    let isRequiredFilled = true
    try {
        if (item?.IsRequired) {
            const valueType =
                item?.AzFieldTypeSelectionRuleTypeID === 3
                    ? 'AssignedValues'
                    : 'AssignedValue'

            if (
                item?.SelectionRuleType === 'Range' &&
                (item?.SelectionRule === 'FreeTextRange' ||
                    item?.SelectionRule === 'FixedRange')
            ) {
                if (!item?.AssignedValueFrom || !item?.AssignedValueTo) {
                    isRequiredFilled = false
                }
            } else {
                if (typeof item?.[valueType] === 'string') {
                    if (!item[valueType]) {
                        isRequiredFilled = false
                    }
                } else if (Array.isArray(item?.[valueType])) {
                    if (!item[valueType]?.length) {
                        isRequiredFilled = false
                    }
                } else {
                    if (!item[valueType]?.ValueName) {
                        isRequiredFilled = false
                    }
                }
            }
        }
    } catch (err) {}
    return isRequiredFilled
}

export const checkFieldTypeWithValidSourceValues = (item: any) => {
    let isValidFieldValue = true
    try {
        if (item?.IsRequired) {
            if (
                item?.SelectionRuleType !== 'Range' &&
                item?.SelectionRule !== 'FixedRange' &&
                item?.SelectionRule !== 'FreeTextRange' &&
                item?.SelectionRule !== 'FixedSingleValue' &&
                item?.SelectionRule !== 'FreeTextSingleValue' &&
                item?.SelectionRule !== 'FixedList'
            ) {
                const sourceValue = item?.SourceValues
                if (!sourceValue) {
                    isValidFieldValue = false
                } else {
                    if (Array.isArray(sourceValue)) {
                        if (!sourceValue?.length) {
                            isValidFieldValue = false
                        }
                    } else {
                        if (!sourceValue?.ValueName) {
                            isValidFieldValue = false
                        }
                    }
                }
            } else {
                if (
                    item?.SelectionRule === 'FixedRange' &&
                    (!item?.AssignedValueFrom || !item?.AssignedValueTo)
                ) {
                    isValidFieldValue = false
                }
            }
        }
    } catch (err) {}
    return isValidFieldValue
}

export const getModifiedFieldTypeValue = (orginalData: any, fieldType: any) => {
    let fieldTypeValue = { ...fieldType }
    try {
        const oldFieldType = orginalData.find(
            (x: any) => x.AzFieldTypeID === fieldType.AzFieldTypeID,
        )

        if (oldFieldType) {
            const valueType =
                oldFieldType?.AzFieldTypeSelectionRuleTypeID === 3
                    ? 'AssignedValues'
                    : 'AssignedValue'
            const oldValue = oldFieldType[valueType]
            const newValue = fieldType[valueType]
            // SingleSelectRadioButton
            if (
                fieldType?.SelectionRuleType === 'Range' &&
                (fieldType?.SelectionRule === 'FreeTextRange' ||
                    fieldType?.SelectionRule === 'FixedRange')
            ) {
                // Case for range values (from)
                const oldAssignedVal = oldFieldType?.AssignedValueFrom
                const newAssignedVal = fieldType.AssignedValueFrom
                // Case for range values (to)
                const oldAssignedValTo = oldFieldType.AssignedValueTo
                const newAssignedValTo = fieldType.AssignedValueTo

                // Case for range change
                const oldAssignedRange = oldFieldType.AssignedAllValuesRange
                const newAssignedRange = fieldType.AssignedAllValuesRange
                if (
                    oldAssignedVal !== newAssignedVal ||
                    oldAssignedValTo !== newAssignedValTo ||
                    (oldAssignedRange !== newAssignedRange &&
                        (newAssignedVal || newAssignedValTo))
                ) {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: true,
                        oldValue:
                            (oldAssignedVal && !newAssignedVal) ||
                            (oldAssignedValTo && !newAssignedValTo)
                                ? {
                                      type: 'Range',
                                      oldFrom: oldAssignedVal,
                                      oldTo: oldAssignedValTo,
                                  }
                                : null,
                        ActionType:
                            getActionType(oldAssignedVal, newAssignedVal) ||
                            getActionType(oldAssignedValTo, newAssignedValTo) ||
                            getActionType(oldAssignedRange, newAssignedRange),
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                    }
                }
            } else if (typeof newValue === 'string') {
                if (newValue !== oldValue) {
                    fieldTypeValue = {
                        ...fieldType,
                        ActionType: getActionType(oldValue, newValue),
                        isModified: true,
                        oldValue:
                            oldValue && !newValue
                                ? {
                                      type: 'Single',
                                      oldValue: oldValue,
                                  }
                                : null,
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                    }
                }
            } else if (Array.isArray(newValue)) {
                const changedArrayItems = findChangesInAssignedValues(
                    oldValue || [],
                    newValue,
                    'ValueName',
                )
                // Filter added items
                const addedItems = filterAddedOrRemoved(
                    changedArrayItems,
                    'Added',
                )

                // Filter removed items
                const removedItems = filterAddedOrRemoved(
                    changedArrayItems,
                    'Deleted',
                )

                // Filter un changed items
                const Unchanged = filterAddedOrRemoved(
                    changedArrayItems,
                    'NoChange',
                )
                if (addedItems?.length || removedItems?.length) {
                    fieldTypeValue = {
                        ...fieldType,
                        [valueType]: [
                            ...removedItems,
                            ...addedItems,
                            ...Unchanged,
                        ],
                        isModified: true,
                        ActionType: 'Unchanged',
                        oldValue: {
                            type: 'MultiValue',
                            removed: removedItems.map(
                                (item: any) => item?.FriendlyName,
                            ),
                            added: addedItems.map(
                                (item: any) => item?.FriendlyName,
                            ),
                        },
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                    }
                }
            } else {
                if (oldValue?.ValueName !== newValue?.ValueName) {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: true,
                        ActionType:
                            getActionType(
                                oldValue?.ValueName,
                                newValue?.ValueName,
                            ) || getActionType(oldValue, newValue),
                        oldValue:
                            (oldValue?.ValueName && !newValue?.ValueName) ||
                            (oldValue && !newValue)
                                ? {
                                      type: 'Single',
                                      oldValue: oldValue?.ValueName || oldValue,
                                  }
                                : null,
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                    }
                }
            }
        }
        if (fieldTypeValue?.isModified) {
            const refinedObject = Object.assign({}, fieldTypeValue)
            delete refinedObject.SourceValues
            delete refinedObject.totalCount

            fieldTypeValue = {
                ...refinedObject,
                AssignedAllValuesRange:
                    fieldTypeValue.AssignedAllValuesRange || false,
            }
        } else {
            const refinedObject = Object.assign({}, fieldTypeValue)
            delete refinedObject.SourceValues
            delete refinedObject.ActionType
            delete refinedObject.totalCount
            delete refinedObject.oldValue

            fieldTypeValue = {
                ...refinedObject,
                AssignedAllValuesRange:
                    fieldTypeValue.AssignedAllValuesRange || false,
            }
        }
    } catch (err) {}

    return fieldTypeValue
}

const filterAddedOrRemoved = (combinedArray: any, type: any) => {
    return combinedArray.filter((obj: any) => obj.ActionType === type)
}

const getActionType = (oldVal: any, newVal: any) => {
    try {
        let tOldVal = oldVal
        let tNewVal = newVal
        if (typeof newVal !== 'string') {
            tOldVal = oldVal?.ValueName
            tNewVal = newVal?.ValueName
        }
        if (!tOldVal && tNewVal) {
            return 'Added'
        }
        if (tOldVal && !tNewVal) {
            return 'Deleted'
        }
        if (tOldVal && tNewVal && tOldVal !== tNewVal) {
            return 'Updated'
        }
    } catch (err) {}
    return ''
}

export const DEFAULT_PAGE_SIZE_DETAILS = 6

export const mergeCartAndApiJson = (cartJson: any, apiJson: any) => {
    let modifiedJson = { ...cartJson }
    try {
        if (modifiedJson?.Rights?.length) {
            const newData = modifiedJson?.Rights?.map((right: any) => {
                const apiRight = apiJson?.Rights?.find(
                    (x: any) => x.AzLocalRightID === right.AzLocalRightID,
                )
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((i: any) => {
                        const apiField = apiRight?.FieldType?.find(
                            (x: any) => x.AzFieldTypeID === i.AzFieldTypeID,
                        )
                        const valueType =
                            i?.AzFieldTypeSelectionRuleTypeID === 3
                                ? 'AssignedValues'
                                : 'AssignedValue'
                        const value = i[valueType]
                        return {
                            ...i,
                            SourceValues: apiField.SourceValues,
                            isModified: false,
                            [valueType]: Array.isArray(value)
                                ? value
                                      .filter((x) => x.ActionType !== 'Deleted')
                                      .map((item) => {
                                          const refinedObject = Object.assign(
                                              {},
                                              item,
                                          )
                                          delete refinedObject.ActionType
                                          return refinedObject
                                      })
                                : value,
                        }
                    })
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...modifiedJson, Rights: newData }
        } else {
            if (modifiedJson?.FieldType?.length) {
                const apiFieldType = apiJson.FieldType
                const fieldTypes = modifiedJson.FieldType.map((i: any) => {
                    const apiField = apiFieldType?.find(
                        (x: any) => x.AzFieldTypeID === i.AzFieldTypeID,
                    )
                    const valueType =
                        i?.AzFieldTypeSelectionRuleTypeID === 3
                            ? 'AssignedValues'
                            : 'AssignedValue'
                    const value = i[valueType]
                    return {
                        ...i,
                        SourceValues: apiField.SourceValues,
                        isModified: false,
                        [valueType]: Array.isArray(value)
                            ? value
                                  .filter((x) => x.ActionType !== 'Deleted')
                                  .map((item) => {
                                      const refinedObject = Object.assign(
                                          {},
                                          item,
                                      )
                                      delete refinedObject.ActionType
                                      return refinedObject
                                  })
                            : value,
                    }
                })
                return { ...modifiedJson, FieldType: fieldTypes }
            }
            return modifiedJson
        }
    } catch (err) {}
    return modifiedJson
}

export const getNumberOrDefault = (number: number, defaultValue: number) => {
    if (isNaN(number)) return defaultValue
    return number
}

export const updateJsonMappingWithApiFormat = (data: any, isApiFormat: any) => {
    try {
        if (data?.Rights?.length) {
            const newData = data?.Rights?.map((right: any) => {
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((field: any) => {
                        const valueType =
                            field?.AzFieldTypeSelectionRuleTypeID === 3
                                ? 'AssignedValues'
                                : 'AssignedValue'
                        let fieldValue = field[valueType]
                        if (field?.AzFieldTypeSelectionRuleTypeID !== 3) {
                            if (isApiFormat && fieldValue) {
                                fieldValue = [fieldValue]
                            } else if (!isApiFormat && fieldValue?.length) {
                                fieldValue = fieldValue?.[0]
                            }
                        }
                        return {
                            ...field,
                            [valueType]: fieldValue,
                            parentRightId: right.AzLocalRightID,
                        }
                    })
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...data, Rights: newData }
        } else {
            if (data?.FieldType?.length) {
                const fieldTypes = data.FieldType.map((field: any) => {
                    const valueType =
                        field?.AzFieldTypeSelectionRuleTypeID === 3
                            ? 'AssignedValues'
                            : 'AssignedValue'
                    let fieldValue = field[valueType]
                    if (field?.AzFieldTypeSelectionRuleTypeID !== 3) {
                        if (isApiFormat && fieldValue) {
                            fieldValue = [fieldValue]
                        } else if (!isApiFormat && fieldValue?.length) {
                            fieldValue = fieldValue?.[0]
                        }
                    }
                    return {
                        ...field,
                        [valueType]: fieldValue,
                        parentRightId: data.AzLocalRightID,
                    }
                })
                return { ...data, FieldType: fieldTypes }
            }
            return data
        }
    } catch (err) {}
    return data
}

export const DEFAULT_LOOKUP_ATTRIBUTES = [
    {
        name: 'FriendlyName',
        value: 'FriendlyName',
        label: 'FriendlyName',
    },
    {
        name: 'ValueValue',
        value: 'ValueName',
        label: 'Value',
    },
]
export const sortArrayObjects = (
    array: any,
    sortByName: any,
    sortOrder: any,
) => {
    return [...array].sort((a, b) => {
        if (sortOrder === 'asc') {
            return a[sortByName] > b[sortByName] ? 1 : -1
        } else {
            return a[sortByName] < b[sortByName] ? 1 : -1
        }
    })
}

export const mergeByProperties = (array1: any, array2: any, prop: any) => {
    //Array1 is with required prop and array 2 without prop
    // Clone the array2 to avoid mutating the original array
    const updatedArray2 = array2.map((obj: any) => ({ ...obj }))

    // Iterate through each object in array2
    updatedArray2.forEach((obj2: any) => {
        // Find a matching object in array1
        const matchingObj = array1.find((obj1: any) => {
            const keysToCompare = Object.keys(obj2)
            // Check if all keys match
            return keysToCompare.every((key) => obj1[key] === obj2[key])
        })

        // If a matching object is found, assign its id to the current object in array2
        if (matchingObj) {
            obj2[prop] = matchingObj?.[prop]
        }
    })
    return updatedArray2
}

export const PREDEFINED_JUSTIFICATION = [
    {
        id: 7423,
        name: 'ITShop_Justifications_JobPositionChange',
        value: 'Justifications_JobPositionChange',
    },
    {
        id: 7426,
        name: 'ITShop_Justifications_New_Application_Rollout',
        value: 'Justifications_New_Application_Rollout',
    },
    {
        id: 7424,
        name: 'ITShop_Justifications_NewAdditionalResponsibilitie',
        value: 'Justifications_NewAdditionalResponsibilitie',
    },
    {
        id: 7422,
        name: 'ITShop_Justifications_AccessForNewEmployee',
        value: 'Justifications_AccessForNewEmployee',
    },
    {
        id: 7425,
        name: 'ITShop_Justifications_SubstitutionColleague',
        value: 'Justifications_SubstitutionColleague',
    },
]
