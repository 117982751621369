import {
    useGetControlsAccess,
    isNilOrEmpty,
    useGetPagesAccess,
} from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)
    const pages = useGetPagesAccess().map((p) => p.name)

    const hasAccessToControl = (controlName) => {
        return controls.includes(controlName) ? controlName : ''
    }

    const trimControlsBasedOnAccess = (controlsList) =>
        controlsList.filter((f) =>
            f.requireAccess ? hasAccessToControl(f.requireAccess) : true,
        )

    const hasAccessToCartDueDate = hasAccessToControl(
        'ResourceAdmin-CartDueDate-Control',
    )

    const canCheckInActiveCheckouts = hasAccessToControl(
        'ResourceAdmin-CheckInActiveCheckouts-Control',
    )

    const canAddClientSecret = hasAccessToControl(
        'ResourceAdmin-AddClientSecret-Control',
    )

    const canAddClientCertificate = hasAccessToControl(
        'ResourceAdmin-AddClientCertificate-Control',
    )

    const canAddScope = hasAccessToControl('ResourceAdmin-AddScope-Control')

    const hasAccessToSeeApproverControl = hasAccessToControl(
        'ResourceAdmin-ShowCartApprover-Control',
    )

    const hasAccessToSeeApplicationsByClaimsMappingPolicy = hasAccessToControl(
        'ResourceAdmin-Applications-ClaimsMappingPolicy-Overview-Applications-Control',
    )

    const hasAccessToSeeClaimsByClaimsMappingPolicy = hasAccessToControl(
        'ResourceAdmin-Applications-ClaimsMappingPolicy-Claims-Control',
    )
    const canAddNewAppManagementRoles = hasAccessToControl(
        'ResourceAdmin-AddNewManagementRoles-Control',
    )

    const canAddNewAccessManagement = hasAccessToControl(
        'ResourceAdmin-AddNewAccessAssignment-Control',
    )

    const canSeeAtLeastOneFilter = (resourceType) =>
        resourceType.filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        ).length > 0
    const getAccessBasedAttributes = (attributes) => {
        const result = attributes.filter((a) => {
            if (isNilOrEmpty(a.requireAccess)) {
                return true
            }
            if (controls.findIndex((x) => x === a.requireAccess.control) > -1) {
                return true
            }
            return false
        })

        return result
    }
    const trimPagesBasedOnAccess = (pagesList) =>
        pagesList.filter((f) =>
            f.requireAccess ? pages.includes(f.requireAccess) : true,
        )

    const canAddApplicationAppRightAssignments = hasAccessToControl(
        'ResourceAdmin-AddApplicationAppRightAssignments-Control',
    )

    const canBulkDeleteApplicationAppRightAssignments = hasAccessToControl(
        'ResourceAdmin-BulkDeleteApplicationAppRightAssignments-Control',
    )
    const canBulkDeleteApplicationAppRoleAssignments = hasAccessToControl(
        'ResourceAdmin-BulkDeleteApplicationAppRoleAssignments-Control',
    )
    const canSelectApplicationAppRightAssignments = hasAccessToControl(
        'ResourceAdmin-SelectApplicationAppRightAssignments-Control',
    )
    const canSelectApplicationAppRoleAssignments = hasAccessToControl(
        'ResourceAdmin-SelectApplicationAppRoleAssignments-Control',
    )
    const canAssignApplicationAppRightToPeople = hasAccessToControl(
        'ResourceAdmin-AssignApplicationAppRightToPeople-Control',
    )

    const canDeleteApplicationAppRight = hasAccessToControl(
        'ResourceAdmin-DeleteApplicationAppRight-Control',
    )

    const canConfigureEligibilityApplicationAppRight = hasAccessToControl(
        'ResourceAdmin-ConfigureEligibilityApplicationAppRight-Control',
    )

    return {
        canSeeAtLeastOneFilter,
        canCheckInActiveCheckouts,
        trimControlsBasedOnAccess,
        getAccessBasedAttributes,
        controls,
        pages,
        trimPagesBasedOnAccess,
        canAddClientSecret,
        canAddClientCertificate,
        canAddScope,
        hasAccessToCartDueDate,
        hasAccessToSeeApproverControl,
        hasAccessToSeeApplicationsByClaimsMappingPolicy,
        hasAccessToSeeClaimsByClaimsMappingPolicy,
        canAddNewAppManagementRoles,
        canAddNewAccessManagement,
        hasAccessToControl,
        canAddApplicationAppRightAssignments,
        canBulkDeleteApplicationAppRightAssignments,
        canSelectApplicationAppRightAssignments,
        canAssignApplicationAppRightToPeople,
        canDeleteApplicationAppRight,
        canConfigureEligibilityApplicationAppRight,
        canBulkDeleteApplicationAppRoleAssignments,
        canSelectApplicationAppRoleAssignments,
    }
}
export default useSubcomponents
